'use client';
import { useEffect, useMemo, useState } from 'react';
import type { Currency } from '@whoop/i18n/types/internationalization';
import type {
  MediaItem,
  Optional,
  ProductInfo,
  ProductItem,
  ProductNode,
  ProductOption,
  RadioInputOption,
} from '../types';
import type { EngravingType } from './engravingUtils';
import { ENGRAVING_TYPES } from './engravingUtils';
import { sortSizes } from './productHelpers';

type OptionType = 'color' | 'size' | 'inseam';
const OPTION_TYPES: OptionType[] = ['color', 'size', 'inseam'];

export type Gender = 'unisex' | 'womens' | 'mens';
const GENDERS: Gender[] = ['unisex', 'womens', 'mens'];

export interface ProductOptionSelectProps {
  value: string;
  onChange: (handle: string) => void;
  options: RadioInputOption[];
}

export type MultiProductOptionProps = {
  label?: string;
  subLabel?: string;
} & ProductOptionSelectProps;

const isFalsyOrEmpty = <T>(value: Optional<T>): boolean =>
  value instanceof Array ? value.length <= 0 : !value;

/**
 * Searches for a product info value for a given ProductItem selection.
 * Search happens in a waterfall style, meaning if there is no value available
 *  for a given node, it will fallback to it's parent.
 *
 * The methods below use this selection logic
 *
 * @param selection ProductItem from within this node
 * @param node ProductNode to search
 * @param getFn Function to get the actual info
 */
const getFromSelection = <T>(
  getFn: (product_info: ProductInfo) => T | undefined,
  selection?: ProductItem,
  node?: ProductNode,
): T | undefined => {
  if (!node) return;
  const t = getFromSelectionRecurse(getFn, selection, node);
  if (!t) {
    return getFn(node.product_info); // default to parent
  }
  return t;
};

// recursive helper only intended to be used by getFromSelection()
const getFromSelectionRecurse = <T>(
  getFn: (product_info: ProductInfo) => T | undefined,
  selection?: ProductItem,
  node?: ProductNode,
  fallback?: T,
): T | undefined => {
  const isItemInThisNode = Boolean(
    node?.product_info.items?.find(
      ({ sku }) => selection?.sku && selection.sku === sku,
    ),
  );
  const fromThis = getFn(node?.product_info ?? { handle: '', title: '' });
  const t = isFalsyOrEmpty(fromThis) ? fallback : fromThis;
  const fromChildren = node?.children
    .map((child) => getFromSelectionRecurse(getFn, selection, child, t))
    ?.find((t) => Boolean(t));
  return isItemInThisNode ? t : fromChildren;
};

/**
 * @deprecated The method should not be used except in legacy components
 * Searches for a product description for a given ProductItem selection.
 * Search happens in a waterfall style, meaning if there is no value available
 *  for a given node, it will fallback to it's parent.
 *
 * @param selection selected ProductItem from within this node
 * @param node node to search
 */
export const getDescriptionFromSelection = (
  selection?: ProductItem,
  node?: ProductNode,
): string | undefined =>
  getFromSelection((info: ProductInfo) => info.description, selection, node);

/**
 * @deprecated The method should not be used except in legacy components
 * Searches for product media for a given ProductItem selection.
 * Search happens in a waterfall style, meaning if there is no value available
 *  for a given node, it will fallback to it's parent.
 *
 * @param selection selected ProductItem from within this node
 * @param node node to search
 */
export const getMediaFromSelection = (
  selection?: ProductItem,
  node?: ProductNode,
): MediaItem[] | undefined => {
  if (selection?.media?.length && selection.media.length > 0) {
    return selection.media;
  }
  return getFromSelection((info: ProductInfo) => info.media, selection, node);
};

/**
 * Returns a memoized sorted list of sizes
 * @param sizes list of ProductOptions
 */
const useSortedSizes = (sizes: ProductOption[]): ProductOption[] =>
  useMemo(() => sortSizes(sizes), [sizes]);

/**
 * @deprecated The method should not be used except in legacy components
 * Returns a flattened list of ProductItem's from a node
 * @param node a ProductNode
 */
export const flattenNodeItems = (node?: ProductNode): ProductItem[] => {
  if (node?.product_info.items) {
    return node.product_info.items.concat(
      node.children.map(flattenNodeItems).flat(),
    );
  }
  return [];
};

// Memoized flat node items
const useFlattenNodeItems = (node?: ProductNode): ProductItem[] =>
  useMemo(() => flattenNodeItems(node), [node]);

/**
 * Returns the currently selected ProductItem and some utility functions to
 * update the currently selected item from a node
 *
 * @param node a ProductNode
 * @param value option ProductItem to override selection with
 * @param onOptionSelected optional callback for analytics or side effects
 */
const useProductSelectionState = (
  value: Optional<ProductItem>,
  node?: ProductNode,
  onOptionSelected?: (option: string, type: string, item: ProductItem) => void,
): [
  selection: ProductItem,
  updateSelection: (handle: string, type: string) => void,
  findSelection: (handle: string, type: string) => ProductItem | undefined,
] => {
  const items = useFlattenNodeItems(node);
  const [selection, setSelection] = useState<ProductItem>(value || items[0]);
  // update state if external value changes
  useEffect(() => {
    if (value) {
      setSelection(value);
    }
  }, [value]);

  const findSelection = (
    handle: string,
    type: string,
  ): ProductItem | undefined => {
    return items.find((product) =>
      OPTION_TYPES.reduce(
        (acc, typeToCheck) =>
          acc &&
          (type === typeToCheck
            ? product[typeToCheck]?.handle === handle
            : product[typeToCheck]?.handle === selection[typeToCheck]?.handle),
        true,
      ),
    );
  };

  const updateSelection = (handle: string, type: string) => {
    const foundItem = findSelection(handle, type);
    if (foundItem) {
      onOptionSelected?.(handle, type, foundItem);
      setSelection(foundItem);
    }
  };

  return [selection, updateSelection, findSelection];
};

/**
 * @deprecated The method should not be used except in legacy components
 * Returns a list of props to use in a <SwatchSelector /> component
 * to handle selection logic
 *
 * @param type OptionType
 * @param options list of ProductOptions
 * @param selection from useProductSelectionState hook
 * @param updateSelection from useProductSelectionState hook
 * @param findSelection from useProductSelectionState hook
 * @param showIfOnlyOne will show swatches even if there is only one option (used for product hierarchy)
 * @param optionName in the case that this is a multi product it is possible that two multi product categories
 *     share the same option name. If this is present the selection will not just look at the product based on
 *     the handle, but will also include the option name.
 * @param quantityGroup used for grouping quantities by option. For example, if we want to cross out
 *     a particular color only if all of the sizes are OOS
 */
const createProductOptionSelectProps = (
  type: OptionType,
  options: ProductOption[],
  selection: ProductItem,
  updateSelection: (handle: string, type: string) => void,
  findSelection: (handle: string, type: string) => ProductItem | undefined,
  showIfOnlyOne?: boolean,
  quantityGroup?: ProductItem[],
): ProductOptionSelectProps | undefined => {
  if (options.length > 0 && (options.length > 1 || showIfOnlyOne)) {
    return {
      value: selection[type]?.handle ?? '',
      onChange: (value) => updateSelection(value, type),
      options: options.map(({ label, handle, swatch }) => {
        const selectionFound = findSelection(handle, type);
        const quantity = !quantityGroup
          ? selectionFound?.quantity
          : quantityGroup
              .filter((item) => item[type]?.handle === handle) // all items for this option
              .reduce((max, item) => Math.max(max, item.quantity || 0), 0);
        return {
          value: handle,
          label,
          background: swatch,
          disabled: !selectionFound,
          crossOut: !quantity,
        };
      }),
    };
  }
};

/**
 * @deprecated This method should not be used except in legacy components
 * Returns the currently selected ProductItem and Props to be used by a swatch selector
 * for all colors in a node
 * @param node a ProductNode
 * @param value optional value to override with
 * @param onOptionSelected optional callback for analytics or side effects
 */
export const useFlattenedProductOptions = (
  node: ProductNode,
  value: Optional<ProductItem>,
  onOptionSelected?: (option: string, type: string, item: ProductItem) => void,
): [
  selection: ProductItem,
  sizeSelectProps: ProductOptionSelectProps | undefined,
  inseamSelectProps: ProductOptionSelectProps | undefined,
  colorSelectProps: ProductOptionSelectProps | undefined,
] => {
  const [selection, updateSelection, findSelection] = useProductSelectionState(
    value,
    node,
    onOptionSelected,
  );
  const { colors, sizes, inseams } = node.product_info;
  const sortedSizes = useSortedSizes(sizes ?? []);

  const colorSelectProps = createProductOptionSelectProps(
    'color',
    colors ?? [],
    selection,
    updateSelection,
    findSelection,
    false,
    undefined,
  );
  const sizeSelectProps = createProductOptionSelectProps(
    'size',
    sortedSizes,
    selection,
    updateSelection,
    findSelection,
  );
  const inseamSelectProps = createProductOptionSelectProps(
    'inseam',
    inseams ?? [],
    selection,
    updateSelection,
    findSelection,
  );

  return [selection, sizeSelectProps, inseamSelectProps, colorSelectProps];
};

/**
 * @deprecated The method should not be used except in legacy components
 * Returns the currently selected ProductItem and Props to be used by a swatch selector
 * splitting the colors if necessary
 * @param node a ProductNode
 * @param value optional value to override with
 * @param onOptionSelected optional callback for analytics or side effects
 */
export const useMultiProductOptions = (
  value: Optional<ProductItem>,
  node?: ProductNode,
  onOptionSelected?: (option: string, type: string, item: ProductItem) => void,
): [
  selection: ProductItem,
  sizeSelectProps: ProductOptionSelectProps | undefined,
  inseamSelectProps: ProductOptionSelectProps | undefined,
  ...colorSelectProps: (MultiProductOptionProps | undefined)[],
] => {
  const [selection, updateSelection, findSelection] = useProductSelectionState(
    value,
    node,
    onOptionSelected,
  );
  const { colors, sizes, inseams } = node?.product_info || {};
  const sortedSizes = useSortedSizes(sizes ?? []);
  const sizeSelectProps = createProductOptionSelectProps(
    'size',
    sortedSizes,
    selection,
    updateSelection,
    findSelection,
  );
  const inseamSelectProps = createProductOptionSelectProps(
    'inseam',
    inseams ?? [],
    selection,
    updateSelection,
    findSelection,
  );

  // if any children have a category label this is a multi-product
  const isMultiProduct = node?.children.find((childNode) =>
    Boolean(childNode.product_info.category_label),
  );
  let multiProducts: (MultiProductOptionProps | undefined)[];

  if (isMultiProduct) {
    multiProducts =
      node?.children
        .sort((child1, child2) =>
          (child1.product_info.category_label || '').localeCompare(
            child2.product_info.category_label || '',
          ),
        )
        ?.sort((child1) => (child1.product_info.handle === 'remy' ? -1 : 0)) // BLB override so Remy is first
        ?.map((node) => {
          const productOptionSelectProps = createProductOptionSelectProps(
            'color',
            node.product_info.colors ?? [],
            selection,
            updateSelection,
            findSelection,
            true,
          );

          return productOptionSelectProps
            ? {
                label: node.product_info.category_label,
                ...productOptionSelectProps,
              }
            : undefined;
        }) ?? [];
  } else {
    const productOptionSelectProps = createProductOptionSelectProps(
      'color',
      colors ?? [],
      selection,
      updateSelection,
      findSelection,
    );

    multiProducts = [
      productOptionSelectProps
        ? {
            ...productOptionSelectProps,
          }
        : undefined,
    ];
  }

  return [
    selection,
    sizeSelectProps,
    inseamSelectProps,
    ...multiProducts.filter(
      (object) => object && Boolean(Object.keys(object).length),
    ),
  ];
};

/**
 * @deprecated The method should not be used except in legacy components
 * This is necessary due to how product-service returns SKUs
 * We should not use this method outside of the product details modal
 */
export const wrapNode = (
  node: ProductNode,
  currency: Currency,
): ProductNode => ({
  product_info: {
    ...node.product_info,
    colors: node.product_info.colors?.map((color) => ({
      ...color,
      swatch: `url(https://cdn.shopify.com/s/files/1/1040/0152/files/${color.handle}_64x64.png)`,
    })),
    items: node.product_info.items?.map((item) => ({
      ...item,
      quantity: getQuantity(item, currency),
    })),
  },
  children: node.children.map((node) => wrapNode(node, currency)),
});

/**
 * @deprecated Do not use this method!
 * @param item
 * @param currency
 * @returns
 */
export const getQuantity = function (
  item: ProductItem,
  currency: Currency,
): number {
  const sku = item.sku + getSkuSuffix(currency);
  const inventory = item.inventory.find((inventory) => inventory.sku === sku);
  return inventory?.quantity || 0;
};

/**
 * @deprecated Do not use this method!
 * You should be getting the correct SKUs from the backend
 * @param currency
 * @returns
 */
export const getSkuSuffix = (currency: Currency): string => {
  switch (currency) {
    case 'aed':
      return '-U';
    case 'aud':
      return '-A';
    case 'cad':
      return '-C';
    case 'eur':
      return '-E';
    case 'gbp':
      return '-G';
    case 'nzd':
      return '-A';
    case 'usd':
    default:
      return '';
  }
};

/**
 * @deprecated The method should not be used except in legacy components
 * Searches for product engraving types for a given ProductItem selection.
 * Search happens in a waterfall style, meaning if there is no value available
 *  for a given node, it will fallback to it's parent.
 *
 * @param selection selected ProductItem from within this node
 * @param node node to search
 */
export const getEngravingTypesFromSelection = (
  selection?: ProductItem,
  node?: ProductNode,
): Optional<EngravingType[]> => {
  return getFromSelection(
    (info) => {
      const arr = ENGRAVING_TYPES.filter((t) => info[`engraving_${t}`]);
      return arr.length > 0 ? arr : undefined;
    },
    selection,
    node,
  );
};

/**
 * @deprecated The method should not be used except in legacy components
 * Searches for product gender for a given ProductItem selection.
 * Search happens in a waterfall style, meaning if there is no value available
 *  for a given node, it will fallback to it's parent.
 *
 * @param selection selected ProductItem from within this node
 * @param node node to search
 */
export const getGenderFromSelection = (
  selection?: ProductItem,
  node?: ProductNode,
): Gender | undefined => {
  return getFromSelection(
    (info) => GENDERS.find((gender) => Boolean(info[gender])),
    selection,
    node,
  );
};

/**
 * @deprecated The method should not be used except in legacy components
 * Searches for product sizing guide for a given ProductItem selection.
 * Search happens in a waterfall style, meaning if there is no value available
 *  for a given node, it will fallback to it's parent.
 *
 * @param selection selected ProductItem from within this node
 * @param node node to search
 */
export const getSizingGuideFromSelection = (
  selection?: ProductItem,
  node?: ProductNode,
): Optional<string> => {
  return getFromSelection((info) => info.sizing_guide, selection, node);
};
